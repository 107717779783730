/// <reference types="../assets" />
import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide, mediaXWide } from '@xing-com/layout-tokens';
import {
  cornerRadiusM,
  scale005,
  scale010,
  scale110,
  scale130,
  scale160,
  scale190,
  scale220,
  scale250,
  scale280,
  scale310,
  scale340,
  xdlColorBackgroundTertiary,
  xdlColorControlFillSecondary,
} from '@xing-com/tokens';

import maskImage from '../assets/super-ellipse-mask.svg';
import type { SuperEllipseSize } from './super-ellipse.types';

type SuperEllipseTransientProps = {
  $size?: SuperEllipseSize;
  $sizeConfined?: SuperEllipseSize;
  $sizeWide?: SuperEllipseSize;
  $sizeXWide?: SuperEllipseSize;
};

type ImageContainerTransientProps = {
  $blur?: boolean;
  $blurValue?: string;
};

// *** Sizes ***
export const sizes = {
  xxsmall: css`
    height: ${scale110};
    width: ${scale110};

    & .strokeWidth {
      stroke-width: ${scale005};
    }
  `,
  xsmall: css`
    height: ${scale130};
    width: ${scale130};

    & .strokeWidth {
      stroke-width: ${scale005};
    }
  `,
  small: css`
    height: ${scale160};
    width: ${scale160};

    & .strokeWidth {
      stroke-width: ${scale005};
    }
  `,
  medium: css`
    height: ${scale190};
    width: ${scale190};

    & .strokeWidth {
      stroke-width: ${scale005};
    }
  `,
  large: css`
    height: ${scale220};
    width: ${scale220};

    & .strokeWidth {
      stroke-width: ${scale010};
    }
  `,
  xlarge: css`
    height: ${scale250};
    width: ${scale250};

    & .strokeWidth {
      stroke-width: ${scale010};
    }
  `,
  xxlarge: css`
    height: ${scale280};
    width: ${scale280};

    & .strokeWidth {
      stroke-width: ${scale010};
    }
  `,
  xxxlarge: css`
    height: ${scale310};
    width: ${scale310};

    & .strokeWidth {
      stroke-width: ${scale010};
    }
  `,
  xxxxlarge: css`
    height: ${scale340};
    width: ${scale340};

    & .strokeWidth {
      stroke-width: ${scale010};
    }
  `,
};

// *** Components ***
export const SuperEllipse = styled.div<SuperEllipseTransientProps>`
  ${({ $size }) => $size && sizes[$size]};
  position: relative;

  & .strokeWidth {
    stroke: ${xdlColorBackgroundTertiary};
  }

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};

  ${({ $sizeXWide }) =>
    $sizeXWide &&
    css`
      @media ${mediaXWide} {
        ${sizes[$sizeXWide]};
      }
    `};
`;

export const ImageContainer = styled.div<ImageContainerTransientProps>`
  display: block;
  height: 100%;
  mask-image: url(${maskImage});
  mask-position: center;
  mask-repeat: no-repeat;
  position: absolute;
  width: 100%;

  ${({ $blur, $blurValue }) =>
    $blur && $blurValue
      ? css`
          filter: blur(${$blurValue});
          pointer-events: none;
        `
      : null};
`;

export const Image = styled.img`
  aspect-ratio: 1 / 1;
  background-color: ${xdlColorControlFillSecondary};
  border-radius: ${cornerRadiusM};
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const ImageSvgBorder = styled.svg`
  height: 100%;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  margin: auto;
  padding: 0;
  pointer-events: none;
  position: relative;
  width: 100%;
`;
