import styled, { css } from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  cornerRadiusS,
  scale080,
  scale100,
  spaceS,
  spaceXS,
  xdlColorControlFillTertiary,
  xdlColorNotification,
  xdlColorPremium,
  xdlColorProBusiness,
  xdlColorProJobsFill,
  xdlColorText,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorUserRoles,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import type { FlagSize, FlagVariant } from './flag.types';

type FlagTransientProps = {
  $size: FlagSize;
  $sizeConfined?: FlagSize;
  $sizeWide?: FlagSize;
  $variant: FlagVariant;
};

type BodyCopyTProps = {
  $variant: FlagVariant;
};

// *** Sizes ***
export const sizes = {
  small: css`
    height: ${scale080};
    padding-inline-end: ${spaceXS};
    padding-inline-start: ${spaceXS};
  `,
  medium: css`
    height: ${scale100};
    padding-inline-end: ${spaceS};
    padding-inline-start: ${spaceS};
  `,
};

// *** Variants ***
export const variants = {
  basic: {
    flag: css`
      background-color: ${xdlColorControlFillTertiary};
    `,
    text: css`
      color: ${xdlColorText};
    `,
  },

  premium: {
    flag: css`
      background-color: ${xdlColorPremium};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  proJobs: {
    flag: css`
      background-color: ${xdlColorProJobsFill};
    `,
    text: css`
      color: ${xdlColorTextOnLight};
    `,
  },
  insider: {
    flag: css`
      background-color: ${xdlColorUserRoles};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  new: {
    flag: css`
      background-color: ${xdlColorNotification};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  beta: {
    flag: css`
      background-color: ${xdlColorNotification};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  // deprecated
  ambassador: {
    flag: css`
      background-color: ${xdlColorUserRoles};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  // deprecated
  moderator: {
    flag: css`
      background-color: ${xdlColorUserRoles};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  // deprecated
  proBusiness: {
    flag: css`
      background-color: ${xdlColorProBusiness};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  // deprecated
  proCoach: {
    flag: css`
      background-color: ${xdlColorPremium};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  // deprecated
  proTrainer: {
    flag: css`
      background-color: ${xdlColorPremium};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
};

// *** Components ***
export const Flag = styled.span<FlagTransientProps>`
  align-items: center !important;
  border-radius: ${cornerRadiusS};
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  ${({ $size }) => sizes[$size]}

  ${({ $sizeConfined }) =>
    $sizeConfined &&
    css`
      @media ${mediaConfined} {
        ${sizes[$sizeConfined]};
      }
    `};

  ${({ $sizeWide }) =>
    $sizeWide &&
    css`
      @media ${mediaWide} {
        ${sizes[$sizeWide]};
      }
    `};

  ${({ $variant }) => variants[$variant].flag}
`;

export const Text = styled(BodyCopy)<BodyCopyTProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;

  ${({ $variant }) => variants[$variant].text}
`;
