/*
This file is autogenerated. Please don’t change it manually.
See packages/xds/designkit/src/handle-variables.ts to make changes.
*/
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

:root {
  --xdlColorBackgroundDark: #161C24;
  --xdlColorBackgroundLight: #FFFFFF;
  --xdlColorCardHero01: #F7F2EB;
  --xdlColorCardHero02: #BD85FB;
  --xdlColorCardHero03: #E8D5FF;
  --xdlColorCardHero04: #46B7BB;
  --xdlColorCardHero05: #BBE7E8;
  --xdlColorCardHero06: #CAD3E2;
  --xdlColorCardHero07: #F5AE87;
  --xdlColorCardHero08: #91D5C2;
  --xdlColorCardInspireText01: #1D2124;
  --xdlColorCardInspireText02: #1D2124;
  --xdlColorCardInspireText03: #1D2124;
  --xdlColorCardInspire01: #91D5C2;
  --xdlColorCardInspire02: #BBE7E8;
  --xdlColorCardInspire03: #E8D5FF;
  --xdlColorCardInspire04: #BD85FB;
  --xdlColorCardInspire05: #46B7BB;
  --xdlColorCardInspire06: #CAD3E2;
  --xdlColorCardInspire07: #F5AE87;
  --xdlColorChart01: #00D296;
  --xdlColorChart02: #0698A0;
  --xdlColorChart03: #C6F16D;
  --xdlColorChart05: #FF847E;
  --xdlColorChart07: #D4699E;
  --xdlColorChart09: #F4164D;
  --xdlColorControlFillSecondary: #F4F4F5;
  --xdlColorDisabledOnColor: #BCC9D1;
  --xdlColorErrorSoft: #F8DDDF;
  --xdlColorFocussed: #0698A0;
  --xdlColorHighlighted: #87D34A;
  --xdlColorHoverOnColor: #BCC9D1;
  --xdlColorHoverOnColorStrong: #5E737D;
  --xdlColorInfo: #97B7F7;
  --xdlColorLink: #0698A0;
  --xdlColorLinkHover: #007B80;
  --xdlColorLinkVisited: #7C8A9C;
  --xdlColorMarkerStrong: #8A2CE8;
  --xdlColorPremium: #0698A0;
  --xdlColorPremiumSecondary: #007B80;
  --xdlColorPricing: #9C195B;
  --xdlColorPrimaryButtonHover: #A7E770;
  --xdlColorProBusinessQuaternary: #E1CFEE;
  --xdlColorProJobs: #009A84;
  --xdlColorProJobsFill: #27DBA8;
  --xdlColorProJobsSecondary: #00D296;
  --xdlColorProgressBadgeBackground01: #FE9A95;
  --xdlColorProgressBadgeBackground02: #FFDA5F;
  --xdlColorProgressBadgeBackground03: #CBF576;
  --xdlColorProgressBadgeBackground04: #27DBA8;
  --xdlColorSelectedSecondary: #0698A0;
  --xdlColorSelectedTertiary: #84D4D5;
  --xdlColorSliderTickMark: #7C8A9C;
  --xdlColorTextOnDark: #FFFFFF;
  --xdlColorTextOnLight: #1D2124;
  --xdlColorTextSecondaryOnDark: #BCC9D1;
  --xdlColorTextSecondaryOnLight: #5E737D;
  --xdlColorTextTertiaryOnDark: #5E737D;
  --xdlColorTextTertiaryOnLight: #8A9DA6;
  --xdlColorUserRoles: #7C8A9C;
  --xdlColorXING: #C6F16D;
  --xdlPaletteBerry01: #FDF1F7;
  --xdlPaletteBerry02: #EFCFDF;
  --xdlPaletteBerry03: #E5BCD0;
  --xdlPaletteBerry04: #DC97BA;
  --xdlPaletteBerry05: #D4699E;
  --xdlPaletteBerry06: #CA4481;
  --xdlPaletteBerry07: #B23975;
  --xdlPaletteBerry08: #9C195B;
  --xdlPaletteBerry09: #940F62;
  --xdlPaletteBerry10: #83095D;
  --xdlPaletteBerry11: #6D0253;
  --xdlPaletteBlue01: #F1F6FE;
  --xdlPaletteBlue02: #E3EDFE;
  --xdlPaletteBlue03: #C9DCFB;
  --xdlPaletteBlue04: #97B7F7;
  --xdlPaletteBlue05: #5790EB;
  --xdlPaletteBlue06: #3C80EB;
  --xdlPaletteBlue07: #2866E6;
  --xdlPaletteBlue08: #1C4FB8;
  --xdlPaletteBlue09: #0D3994;
  --xdlPaletteBlue10: #022B7F;
  --xdlPaletteBlue11: #002166;
  --xdlPaletteGrayCool01: #F2F5F9;
  --xdlPaletteGrayCool02: #E9EEF5;
  --xdlPaletteGrayCool03: #E1E8F5;
  --xdlPaletteGrayCool04: #CAD3E2;
  --xdlPaletteGrayCool05: #ACB8CA;
  --xdlPaletteGrayCool06: #7C8A9C;
  --xdlPaletteGrayCool07: #616D7D;
  --xdlPaletteGrayCool08: #414D5D;
  --xdlPaletteGrayCool09: #293545;
  --xdlPaletteGrayCool10: #212A36;
  --xdlPaletteGrayCool11: #161C24;
  --xdlPaletteGrayPlain01: #F9FAFA;
  --xdlPaletteGrayPlain02: #F4F4F5;
  --xdlPaletteGrayPlain03: #E9EEF0;
  --xdlPaletteGrayPlain04: #DBE2E6;
  --xdlPaletteGrayPlain05: #BCC9D1;
  --xdlPaletteGrayPlain06: #A4B2BA;
  --xdlPaletteGrayPlain07: #8A9DA6;
  --xdlPaletteGrayPlain08: #5E737D;
  --xdlPaletteGrayPlain09: #435057;
  --xdlPaletteGrayPlain10: #343C40;
  --xdlPaletteGrayPlain11: #1D2124;
  --xdlPaletteLime01: #F9FFEF;
  --xdlPaletteLime02: #F3FAE6;
  --xdlPaletteLime03: #E8F5CE;
  --xdlPaletteLime04: #DAF5A4;
  --xdlPaletteLime05: #D3F58E;
  --xdlPaletteLime06: #CBF576;
  --xdlPaletteLime07: #C6F16D;
  --xdlPaletteLime08: #B7EC6F;
  --xdlPaletteLime09: #A7E770;
  --xdlPaletteLime10: #87D34A;
  --xdlPaletteLime11: #67BE24;
  --xdlPaletteMint01: #E0FEF6;
  --xdlPaletteMint02: #C7F8EB;
  --xdlPaletteMint03: #A3F0DB;
  --xdlPaletteMint04: #73EACA;
  --xdlPaletteMint05: #30E5B2;
  --xdlPaletteMint06: #27DBA8;
  --xdlPaletteMint07: #00D296;
  --xdlPaletteMint08: #00C48C;
  --xdlPaletteMint09: #00AD85;
  --xdlPaletteMint10: #009C77;
  --xdlPaletteMint11: #007C5F;
  --xdlPaletteOrange01: #FEE4DC;
  --xdlPaletteOrange02: #FEDAD2;
  --xdlPaletteOrange03: #FECFC8;
  --xdlPaletteOrange04: #FEC0B9;
  --xdlPaletteOrange05: #FEADA9;
  --xdlPaletteOrange06: #FE9A95;
  --xdlPaletteOrange07: #FF847E;
  --xdlPaletteOrange08: #FE7A6C;
  --xdlPaletteOrange09: #FE7C62;
  --xdlPaletteOrange10: #FE6F53;
  --xdlPaletteOrange11: #FE6648;
  --xdlPalettePetrol01: #EEF6F6;
  --xdlPalettePetrol02: #E1F3F3;
  --xdlPalettePetrol03: #BBE7E8;
  --xdlPalettePetrol04: #84D4D5;
  --xdlPalettePetrol05: #46B7BB;
  --xdlPalettePetrol06: #06A7AC;
  --xdlPalettePetrol07: #0698A0;
  --xdlPalettePetrol08: #05888F;
  --xdlPalettePetrol09: #007B80;
  --xdlPalettePetrol10: #006567;
  --xdlPalettePetrol11: #005357;
  --xdlPalettePurpleRain01: #FAF5FF;
  --xdlPalettePurpleRain02: #F2E8FF;
  --xdlPalettePurpleRain03: #E8D5FF;
  --xdlPalettePurpleRain04: #D6B4FE;
  --xdlPalettePurpleRain05: #BD85FB;
  --xdlPalettePurpleRain06: #A456F6;
  --xdlPalettePurpleRain07: #8A2CE8;
  --xdlPalettePurpleRain08: #7A23CD;
  --xdlPalettePurpleRain09: #6822A7;
  --xdlPalettePurpleRain10: #551D86;
  --xdlPalettePurpleRain11: #390863;
  --xdlPalettePurple01: #F8EFFF;
  --xdlPalettePurple02: #EADDF3;
  --xdlPalettePurple03: #E1CFEE;
  --xdlPalettePurple04: #C5ADD1;
  --xdlPalettePurple05: #AE8DBF;
  --xdlPalettePurple06: #9569AB;
  --xdlPalettePurple07: #85599B;
  --xdlPalettePurple08: #73418D;
  --xdlPalettePurple09: #663082;
  --xdlPalettePurple10: #511E6C;
  --xdlPalettePurple11: #40075E;
  --xdlPaletteRed01: #F8E8E9;
  --xdlPaletteRed02: #F8DDDF;
  --xdlPaletteRed03: #FEBDC3;
  --xdlPaletteRed04: #FF8D9A;
  --xdlPaletteRed05: #F25C6E;
  --xdlPaletteRed06: #F34B65;
  --xdlPaletteRed07: #FC2558;
  --xdlPaletteRed08: #F4164D;
  --xdlPaletteRed09: #DD0543;
  --xdlPaletteRed10: #CF0231;
  --xdlPaletteRed11: #BB0034;
  --xdlPaletteWhite: #FFFFFF;
  --xdlPaletteYellow01: #FFF4D1;
  --xdlPaletteYellow02: #FFEFBE;
  --xdlPaletteYellow03: #FDEBB2;
  --xdlPaletteYellow04: #FCE59D;
  --xdlPaletteYellow05: #FEE284;
  --xdlPaletteYellow06: #FFDE70;
  --xdlPaletteYellow07: #FFDA5F;
  --xdlPaletteYellow08: #FFD547;
  --xdlPaletteYellow09: #FDD039;
  --xdlPaletteYellow10: #FDCC25;
  --xdlPaletteYellow11: #FFC911;

}

:root, :root.light, .is-light-theme {
  --xdlColorBackground: #FFFFFF;
  --xdlColorBackgroundSecondary: #F9FAFA;
  --xdlColorBackgroundTertiary: #F4F4F5;
  --xdlColorBeta: #F4164D;
  --xdlColorBorder: #BCC9D1;
  --xdlColorBorderSoft: #DBE2E6;
  --xdlColorBorderStrong: #1D2124;
  --xdlColorButtonSecondaryHover: rgba(138, 157, 166, 0.30);
  --xdlColorButtonStrong: #1D2124;
  --xdlColorButtonStrongHover: #414D5D;
  --xdlColorCaution: #FE7A6C;
  --xdlColorChart04: #5790EB;
  --xdlColorChart06: #73418D;
  --xdlColorChart08: #616D7D;
  --xdlColorControlBorder: #BCC9D1;
  --xdlColorControlBorderSecondary: #1D2124;
  --xdlColorControlBorderTertiary: #FFFFFF;
  --xdlColorControlFill: #FFFFFF;
  --xdlColorControlFillQuaternary: #1D2124;
  --xdlColorControlFillTertiary: #DBE2E6;
  --xdlColorDimming: #161C24;
  --xdlColorDisabled: #DBE2E6;
  --xdlColorDisabledSoft: #E9EEF0;
  --xdlColorDisabledStrong: #BCC9D1;
  --xdlColorError: #F4164D;
  --xdlColorGo: #00AD85;
  --xdlColorHover: #F4F4F5;
  --xdlColorHoverQuaternary: #414D5D;
  --xdlColorHoverSecondary: #E9EEF0;
  --xdlColorHoverTertiary: #BCC9D1;
  --xdlColorInfoSoft: #E3EDFE;
  --xdlColorMarkerHighlight: #F2E8FF;
  --xdlColorMarkerHighlightText: #8A2CE8;
  --xdlColorMarkerMatch: #E1F3F3;
  --xdlColorMarkerMatchText: #007B80;
  --xdlColorMeHubAssessmentHighlight: #A3F0DB;
  --xdlColorMeHubHighlight: #C9DCFB;
  --xdlColorMenuHighlight: #F7F2EB;
  --xdlColorMenuHighlightHover: #F5ECE1;
  --xdlColorMenuHighlightPressed: #EDE0CF;
  --xdlColorMenuPressed: #E9EEF0;
  --xdlColorMenuStrong: #E8D5FF;
  --xdlColorMenuStrongHover: #D6B4FE;
  --xdlColorMenuStrongPressed: #BD85FB;
  --xdlColorModalBorder: #FFFFFF;
  --xdlColorNew: #F4164D;
  --xdlColorNewListItemSoft: #EEF6F6;
  --xdlColorNotification: #F4164D;
  --xdlColorPollIndicatorPrimary: #30E5B2;
  --xdlColorPollIndicatorSecondary: #ACB8CA;
  --xdlColorPremiumTertiary: #E1F3F3;
  --xdlColorProBusiness: #73418D;
  --xdlColorProBusinessSecondary: #663082;
  --xdlColorProBusinessTertiary: #F2F5F9;
  --xdlColorProJobsTertiary: #F2F5F9;
  --xdlColorSelected: #1D2124;
  --xdlColorSkeletonPrimary: #E9EEF0;
  --xdlColorSkeletonPrimaryAnimation: #DBE2E6;
  --xdlColorSkeletonSecondary: #BCC9D1;
  --xdlColorSkeletonSecondaryAnimation: #A4B2BA;
  --xdlColorStop: #F4164D;
  --xdlColorSuccess: #00AD85;
  --xdlColorSuccessSoft: #C7F8EB;
  --xdlColorText: #1D2124;
  --xdlColorTextInvert: #FFFFFF;
  --xdlColorTextSecondary: #5E737D;
  --xdlColorTextTertiary: #8A9DA6;
  --xdlColorUnseen: #F4164D;
  --xdlColorIllustrationOutlinePrimary: #1D2124;
  --xdlColorIllustrationOutlineSecondary: #E9EEF5;
  --xdlColorIllustrationBackground: transparent;
  --xdlColorIllustrationOnColorPrimary: #FEFEFE;
  --xdlColorIllustrationOnColorSecondary: rgba(255, 250, 250, 0.5);
  --xdlColorIllustrationFillTertiaryGrayPlain: #DBE2E6;

}

:root.dark, .is-dark-theme {
  --xdlColorBackground: #161C24;
  --xdlColorBackgroundSecondary: #212A36;
  --xdlColorBackgroundTertiary: #293545;
  --xdlColorBeta: #F34B65;
  --xdlColorBorder: #8A9DA6;
  --xdlColorBorderSoft: #414D5D;
  --xdlColorBorderStrong: #FFFFFF;
  --xdlColorButtonSecondaryHover: rgba(138, 157, 166, 0.40);
  --xdlColorButtonStrong: #C6F16D;
  --xdlColorButtonStrongHover: #A7E770;
  --xdlColorCaution: #FE7C62;
  --xdlColorChart04: #97B7F7;
  --xdlColorChart06: #9569AB;
  --xdlColorChart08: #BCC9D1;
  --xdlColorControlBorder: #616D7D;
  --xdlColorControlBorderSecondary: #FFFFFF;
  --xdlColorControlBorderTertiary: #161C24;
  --xdlColorControlFill: #161C24;
  --xdlColorControlFillQuaternary: #161C24;
  --xdlColorControlFillTertiary: #616D7D;
  --xdlColorDimming: #000000;
  --xdlColorDisabled: #435057;
  --xdlColorDisabledSoft: #414D5D;
  --xdlColorDisabledStrong: #616D7D;
  --xdlColorError: #F34B65;
  --xdlColorGo: #00C48C;
  --xdlColorHover: #293545;
  --xdlColorHoverQuaternary: #BCC9D1;
  --xdlColorHoverSecondary: #435057;
  --xdlColorHoverTertiary: #414D5D;
  --xdlColorInfoSoft: #1C4FB8;
  --xdlColorMarkerHighlight: #293545;
  --xdlColorMarkerHighlightText: #BD85FB;
  --xdlColorMarkerMatch: #293545;
  --xdlColorMarkerMatchText: #06A7AC;
  --xdlColorMeHubAssessmentHighlight: #293545;
  --xdlColorMeHubHighlight: #293545;
  --xdlColorMenuHighlight: #212A36;
  --xdlColorMenuHighlightHover: #293545;
  --xdlColorMenuHighlightPressed: #414D5D;
  --xdlColorMenuPressed: #414D5D;
  --xdlColorMenuStrong: #551D86;
  --xdlColorMenuStrongHover: #6822A7;
  --xdlColorMenuStrongPressed: #7A23CD;
  --xdlColorModalBorder: #414D5D;
  --xdlColorNew: #F34B65;
  --xdlColorNewListItemSoft: #212A36;
  --xdlColorNotification: #F34B65;
  --xdlColorPollIndicatorPrimary: #009C77;
  --xdlColorPollIndicatorSecondary: #414D5D;
  --xdlColorPremiumTertiary: #293545;
  --xdlColorProBusiness: #9569AB;
  --xdlColorProBusinessSecondary: #85599B;
  --xdlColorProBusinessTertiary: #293545;
  --xdlColorProJobsTertiary: #293545;
  --xdlColorSelected: #FFFFFF;
  --xdlColorSkeletonPrimary: #293545;
  --xdlColorSkeletonPrimaryAnimation: #414D5D;
  --xdlColorSkeletonSecondary: #616D7D;
  --xdlColorSkeletonSecondaryAnimation: #7C8A9C;
  --xdlColorStop: #F34B65;
  --xdlColorSuccess: #00C48C;
  --xdlColorSuccessSoft: #007C5F;
  --xdlColorText: #FFFFFF;
  --xdlColorTextInvert: #1D2124;
  --xdlColorTextSecondary: #BCC9D1;
  --xdlColorTextTertiary: #5E737D;
  --xdlColorUnseen: #F34B65;
  --xdlColorIllustrationOutlinePrimary: #435057;
  --xdlColorIllustrationOutlineSecondary: #616D7D;
  --xdlColorIllustrationBackground: #FDFDFD;
  --xdlColorIllustrationOnColorPrimary: #616D7E;
  --xdlColorIllustrationOnColorSecondary: #FFFAFA;
  --xdlColorIllustrationFillTertiaryGrayPlain: #8A9DA5;

}

@media (prefers-color-scheme: dark) {
  :root {
  --xdlColorBackground: #161C24;
  --xdlColorBackgroundSecondary: #212A36;
  --xdlColorBackgroundTertiary: #293545;
  --xdlColorBeta: #F34B65;
  --xdlColorBorder: #8A9DA6;
  --xdlColorBorderSoft: #414D5D;
  --xdlColorBorderStrong: #FFFFFF;
  --xdlColorButtonSecondaryHover: rgba(138, 157, 166, 0.40);
  --xdlColorButtonStrong: #C6F16D;
  --xdlColorButtonStrongHover: #A7E770;
  --xdlColorCaution: #FE7C62;
  --xdlColorChart04: #97B7F7;
  --xdlColorChart06: #9569AB;
  --xdlColorChart08: #BCC9D1;
  --xdlColorControlBorder: #616D7D;
  --xdlColorControlBorderSecondary: #FFFFFF;
  --xdlColorControlBorderTertiary: #161C24;
  --xdlColorControlFill: #161C24;
  --xdlColorControlFillQuaternary: #161C24;
  --xdlColorControlFillTertiary: #616D7D;
  --xdlColorDimming: #000000;
  --xdlColorDisabled: #435057;
  --xdlColorDisabledSoft: #414D5D;
  --xdlColorDisabledStrong: #616D7D;
  --xdlColorError: #F34B65;
  --xdlColorGo: #00C48C;
  --xdlColorHover: #293545;
  --xdlColorHoverQuaternary: #BCC9D1;
  --xdlColorHoverSecondary: #435057;
  --xdlColorHoverTertiary: #414D5D;
  --xdlColorInfoSoft: #1C4FB8;
  --xdlColorMarkerHighlight: #293545;
  --xdlColorMarkerHighlightText: #BD85FB;
  --xdlColorMarkerMatch: #293545;
  --xdlColorMarkerMatchText: #06A7AC;
  --xdlColorMeHubAssessmentHighlight: #293545;
  --xdlColorMeHubHighlight: #293545;
  --xdlColorMenuHighlight: #212A36;
  --xdlColorMenuHighlightHover: #293545;
  --xdlColorMenuHighlightPressed: #414D5D;
  --xdlColorMenuPressed: #414D5D;
  --xdlColorMenuStrong: #551D86;
  --xdlColorMenuStrongHover: #6822A7;
  --xdlColorMenuStrongPressed: #7A23CD;
  --xdlColorModalBorder: #414D5D;
  --xdlColorNew: #F34B65;
  --xdlColorNewListItemSoft: #212A36;
  --xdlColorNotification: #F34B65;
  --xdlColorPollIndicatorPrimary: #009C77;
  --xdlColorPollIndicatorSecondary: #414D5D;
  --xdlColorPremiumTertiary: #293545;
  --xdlColorProBusiness: #9569AB;
  --xdlColorProBusinessSecondary: #85599B;
  --xdlColorProBusinessTertiary: #293545;
  --xdlColorProJobsTertiary: #293545;
  --xdlColorSelected: #FFFFFF;
  --xdlColorSkeletonPrimary: #293545;
  --xdlColorSkeletonPrimaryAnimation: #414D5D;
  --xdlColorSkeletonSecondary: #616D7D;
  --xdlColorSkeletonSecondaryAnimation: #7C8A9C;
  --xdlColorStop: #F34B65;
  --xdlColorSuccess: #00C48C;
  --xdlColorSuccessSoft: #007C5F;
  --xdlColorText: #FFFFFF;
  --xdlColorTextInvert: #1D2124;
  --xdlColorTextSecondary: #BCC9D1;
  --xdlColorTextTertiary: #5E737D;
  --xdlColorUnseen: #F34B65;
  --xdlColorIllustrationOutlinePrimary: #435057;
  --xdlColorIllustrationOutlineSecondary: #616D7D;
  --xdlColorIllustrationBackground: #FDFDFD;
  --xdlColorIllustrationOnColorPrimary: #616D7E;
  --xdlColorIllustrationOnColorSecondary: #FFFAFA;
  --xdlColorIllustrationFillTertiaryGrayPlain: #8A9DA5;

  }
}
`;
