import type * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useFeatures } from '@xing-com/hub';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { Me } from '@xing-com/platform-layout-me';
import { space3XL, spaceM, spaceS, spaceXL } from '@xing-com/tokens';

import { MeMenuItem } from './me-menu-item';

const TopItemsSection = styled.div`
  margin-top: ${spaceXL};

  @media ${mediaTopBarVisible} {
    margin-top: ${space3XL};
  }
`;

const FeatureBasedItemsSection = styled.div`
  margin-top: ${spaceS};
  margin-bottom: ${spaceS};

  @media ${mediaTopBarVisible} {
    margin-top: ${spaceM};
    margin-bottom: ${spaceM};
  }
`;

const BigScreenMeMenuItemContainer = styled.div`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

const SmallScreenMeMenuItemContainer = styled.div`
  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const MeMenuDropdownList: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const features = useFeatures();

  const {
    isPremium,
    hasProJobsMembership,
    showProbusinessInNavigation,
    isJobsPoster,
  } = features.data || {};

  return (
    <>
      <Me
        href="/profile"
        isOnMeMenu
        tracking={{
          sc_o: 'navigation_profile_icon',
        }}
      />

      <TopItemsSection>
        <MeMenuItem
          href="/profile"
          dataTestId="me-menu-item-profile"
          title={formatMessage({ id: 'MEMENU_PROFILE_LABEL' })}
          hasProfileProgressIndicator
          tracking={{
            sc_o: 'navigation_profile',
          }}
        />

        <MeMenuItem
          href="/jobseeker-criteria"
          dataTestId="me-menu-item-jobseeker-criteria"
          title={formatMessage({ id: 'MEMENU_JOB_PREFERENCES_LABEL' })}
          tracking={{
            sc_o: 'navigation_job_preferences',
          }}
        />
        <MeMenuItem
          href="/news/selections/following"
          dataTestId="me-menu-item-following"
          title={formatMessage({ id: 'MEMENU_FOLLOWING_LABEL' })}
          tracking={{
            sc_o: 'navigation_following',
          }}
        />
        <MeMenuItem
          href="/discover/your-posts"
          dataTestId="me-menu-item-your-posts"
          title={formatMessage({ id: 'MEMENU_MY_POSTS_LABEL' })}
          tracking={{
            sc_o: 'navigation_your_posts',
          }}
        />
      </TopItemsSection>

      <FeatureBasedItemsSection>
        {isPremium ? (
          <MeMenuItem
            title={formatMessage({ id: 'MEMENU_PREMIUM_LABEL' })}
            href="/premium"
            dataTestId="me-menu-item-premium"
            color="premium"
            tracking={{
              sc_o: 'navigation_premium',
            }}
          />
        ) : (
          <MeMenuItem
            activeItemValues={['premium']}
            title={formatMessage({ id: 'MEMENU_PREMIUM_FOR_BASIC_USER_LABEL' })}
            href="/purchase/premium?reagent=uplt_1014"
            dataTestId="me-menu-item-premium-upsell"
            color="premium"
            tracking={{
              sc_o: 'navigation_premium',
            }}
          />
        )}

        {hasProJobsMembership ? (
          <MeMenuItem
            title={formatMessage({ id: 'MEMENU_PROJOBS_LABEL' })}
            href="/projobs"
            dataTestId="me-menu-item-projobs"
            color="proJobs"
            tracking={{
              sc_o: 'navigation_projobs',
            }}
          />
        ) : (
          <MeMenuItem
            title={formatMessage({ id: 'MEMENU_PROJOBS_FOR_BASIC_USER_LABEL' })}
            color="proJobs"
            href="/purchase/projobs?reagent=uplt_1015"
            dataTestId="me-menu-item-projobs-upsell"
            tracking={{
              sc_o: 'navigation_projobs',
            }}
          />
        )}
        {showProbusinessInNavigation ? (
          <MeMenuItem
            title={formatMessage({ id: 'MEMENU_PROBUSINESS_LABEL' })}
            href="/probusiness"
            dataTestId="me-menu-item-probusiness"
            color="proBusiness"
            tracking={{
              sc_o: 'navigation_probusiness',
            }}
          />
        ) : null}
      </FeatureBasedItemsSection>

      <SmallScreenMeMenuItemContainer>
        {isJobsPoster ? (
          <MeMenuItem
            title={formatMessage({ id: 'MEMENU_JOB_ADS_LABEL' })}
            href="/jobmanager/online"
            dataTestId="me-menu-item-job-ads"
            tracking={{
              sc_o: 'navigation_manage_jobs_ads',
            }}
          />
        ) : null}
      </SmallScreenMeMenuItemContainer>

      <BigScreenMeMenuItemContainer>
        <MeMenuItem
          href="/settings/purchases"
          dataTestId="me-menu-item-purchases"
          title={formatMessage({
            id: 'VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES',
          })}
          tracking={{
            sc_o: 'navigation_memberships_invoices',
          }}
        />
      </BigScreenMeMenuItemContainer>
      <MeMenuItem
        href="/settings"
        dataTestId="me-menu-item-settings"
        title={formatMessage({ id: 'MEMENU_SETTINGS_LABEL' })}
        tracking={{
          sc_o: 'navigation_settings',
        }}
      />
      <MeMenuItem
        href={`https://faq.xing.com/${locale}`}
        title={formatMessage({ id: 'MEMENU_HELP_LABEL' })}
        dataTestId="me-menu-item-help"
        tracking={{
          sc_o: 'navigation_help',
        }}
      />
      <BigScreenMeMenuItemContainer>
        <MeMenuItem
          href="/login/logout"
          dataTestId="me-menu-item-logout"
          title={formatMessage({
            id: 'VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT',
          })}
          tracking={{
            sc_o: 'navigation_logout',
          }}
        />
      </BigScreenMeMenuItemContainer>
    </>
  );
};
