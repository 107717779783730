import { Router } from '@reach/router';
import type * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { useShowBanner } from '@xing-com/cookie-consent';
import { useRoutingData } from '@xing-com/crate-communication-data-layer';
import { ScrollManager } from '@xing-com/crate-layout-scroll-memo-module';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import type { Xinglet } from '@xing-com/crate-xinglet';
import { XingletLoader } from '@xing-com/crate-xinglet';
import type { InternalHost } from '@xing-com/crate-xinglet/internal';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import GlobalStyles from '@xing-com/global-styles';
import { useScrambledId } from '@xing-com/scrambled-id';

import { NotFound } from './error-components';
import { LoggedInHub, LoggedOutHub } from './hubs';
import { initTracking } from './init-tracking';
import { Route } from './route';
import { useRegisterExperiments } from './use-register-experiments';

type RoutingProps = {
  baseSite?: string;
};

const SetScrambledId: React.FC = () => {
  useScrambledId();
  return null;
};

const Routing: React.FC<RoutingProps> = ({ baseSite }) => {
  const routingData = useRoutingData(baseSite);

  return (
    <>
      <Router>
        {routingData.map((route) => (
          <Route
            path={route.route}
            data={route}
            // All children have the same key to avoid re-mounting when the
            // route changes:
            key="static"
          />
        ))}
        <NotFound default />
      </Router>
    </>
  );
};

// calls ttt.init and sets up the tracking environment
// should always be the first call in the root provider
const ttt = initTracking();

const Root: React.FC<{ baseSite?: string }> = ({ baseSite }) => {
  useRegisterExperiments();
  const { loginState } = useRuntimeConfig();
  const { isPreview = false } = useRuntimeConfig();
  const favicon = isPreview ? 'data:,' : '/favicon.ico';

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <GlobalStyles>
        {loginState !== 'LOGGED_OUT' ? (
          <LoggedInHub loginState={loginState}>
            {/* Skeleton adds conditional rendering for children and displays a
              loading state if the data is not there yet */}
            <Routing baseSite={baseSite} />
            <XingletLoader name="@xing-com/crate-marketing-braze" ttt={ttt} />
            <SetScrambledId />
            <NoSSR>
              <ScrollManager />
            </NoSSR>
          </LoggedInHub>
        ) : (
          <LoggedOutHub>
            <Routing baseSite={baseSite} />
            <XingletLoader name="@xing-com/crate-marketing-braze" ttt={ttt} />
            <NoSSR>
              <ScrollManager />
            </NoSSR>
          </LoggedOutHub>
        )}
      </GlobalStyles>
    </>
  );
};

export default class RootXinglet implements Xinglet {
  /**
   * Used in xinglets/settings/settings/src/pages/privacy/data/tracking/reopen-banner-section.tsx
   */
  public useShowBanner(): unknown {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useShowBanner();
  }

  public getComponent(host: InternalHost): React.ComponentType<{
    basePath: string;
    baseSite?: string;
  }> {
    if (!host.isServer) {
      setTimeout(() => {
        // NOTE: We preload error pages here as it would be too late in case of
        // a network outage
        host.runtime.loadXinglet(
          host,
          '@xing-com/crate-communication-error-pages'
        );
      }, 1000);
    }

    return ({ basePath, baseSite }) => {
      return (
        <XingletLoader
          name="@xing-com/crate-core-hops-environment"
          basePath={basePath}
        >
          {/* Disable scaling of viewport as early as possible */}
          <Helmet>
            <title>XING</title>
            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1, maximum-scale=1"
            />
          </Helmet>
          <Root baseSite={baseSite} />
        </XingletLoader>
      );
    };
  }
}
