import type * as React from 'react';
import styled, { css } from 'styled-components';

import { useViewerData } from '@xing-com/hub';
import { Link } from '@xing-com/link';
import { ProfileImage } from '@xing-com/profile-image';
import { ProgressBadge } from '@xing-com/progress-badge';
import {
  scale010,
  scale100,
  spaceM,
  spaceS,
  spaceXS,
  xdlColorBackground,
  xdlColorDisabledSoft,
  xdlColorText,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';
import { UserFlag } from '@xing-com/user-flag';
import { eitherOr, trackingURL } from '@xing-com/xing-tracking';

type To =
  | string
  | { pathname: string; hash?: string; search?: string; state?: unknown }
  | undefined;

const StyledLink = styled(Link)<{ to: To }>`
  background-color: ${xdlColorBackground};
  color: ${xdlColorText};
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const StyledInnerProfile = styled.div<{
  $collapsed?: boolean;
  $hasProfileProgressIndicator?: boolean;
  $isOnMeMenu?: boolean;
}>`
  display: flex;
  margin: 0;
  align-items: center;

  ${({ $hasProfileProgressIndicator, $collapsed, $isOnMeMenu }) => css`
    align-items: ${($hasProfileProgressIndicator && !$collapsed) || $isOnMeMenu
      ? 'start'
      : 'center'};
  `}

  ${({ $collapsed }) =>
    $collapsed
      ? css`
          flex-direction: column;
          justify-content: center;
        `
      : css`
          flex-direction: row;
          justify-content: flex-start;
        `}
`;

const StyledProfileImage = styled(ProfileImage)<{
  $collapsed?: boolean;
}>`
  display: block;
  ${({ $collapsed }) =>
    $collapsed
      ? css`
          margin: 0 0 ${spaceS} 0;
        `
      : css`
          margin-right: ${spaceM};
        `}
`;

const StyledFigcaption = styled.figcaption<{
  $hasProfileProgressIndicator?: boolean;
  $collapsed?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ $collapsed, $hasProfileProgressIndicator }) =>
    $collapsed &&
    $hasProfileProgressIndicator &&
    css`
      margin-top: ${spaceS};
    `}
`;

const StyledMeMenuUserName = styled(Headline)<{
  $displayName?: string;
}>`
  ${({ $displayName }) => {
    return $displayName
      ? css`
          margin-top: ${spaceS};
        `
      : css`
          background: ${xdlColorDisabledSoft};
          border-radius: ${scale010};
          height: ${scale100};
        `;
  }}
`;

const StyledUserName = styled.p<{
  $displayName?: string;
  $collapsed?: boolean;
}>`
  ${({ $collapsed, $displayName }) =>
    $collapsed
      ? css`
          display: none;
        `
      : $displayName
        ? css`
            font-weight: bold;
            margin: ${spaceXS} 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
          `
        : css`
            background: ${xdlColorDisabledSoft};
            border-radius: ${scale010};
            height: ${scale100};
          `}
`;

const StyledProgressBadge = styled(ProgressBadge)`
  width: fit-content;
`;

type UserTypes = {
  profileImage?: { url: string }[];
  displayName: string;
  gender: keyof typeof genderMap;
  userFlags: {
    displayFlag: React.ComponentProps<typeof UserFlag>['displayFlag'];
  };
};

export type MeProps = {
  className?: string;
  collapsed?: boolean;
  isOnMeMenu?: boolean;
  tracking: Record<string, string | [string, string]>;
  href: string;
  hasProfileProgressIndicator?: boolean;
};

const genderMap = {
  FEMALE: 'female-user',
  MALE: 'male-user',
  NONE: 'neutral-user',
} as const;

export const Me: React.FC<MeProps> = ({
  className,
  collapsed = false,
  tracking,
  href,
  hasProfileProgressIndicator,
  isOnMeMenu = false,
}) => {
  const viewerData = useViewerData();
  const user = viewerData?.data?.user;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const { displayName, profileImage, gender, userFlags } = user as UserTypes;
  const displayFlag = (userFlags && userFlags?.displayFlag) || null;
  const image = profileImage?.[0]?.url;

  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOr(displayName),
  };

  const networkEfficiencyIndicator =
    viewerData?.data?.networkEfficiencyIndicator || {};
  const { score, userTypeSupported } = networkEfficiencyIndicator;

  const showProfileFillingIndicator =
    hasProfileProgressIndicator && userTypeSupported && score && score !== 1;

  const neffiValue = score
    ? Math.ceil(Math.round(score * 100) / 5) * 5
    : 'not_available';
  const trackingProps = !hasProfileProgressIndicator
    ? tracking
    : {
        ...tracking,
        sc_o_PropActionOrigin: `navigation_neffi_${neffiValue}`,
        ...(score && { expandNeffi: true }),
      };

  const completeTrackingURL =
    href &&
    trackingURL(
      href,
      'tracking',
      trackingConditions
    )({ tracking: trackingProps });

  return (
    <StyledLink
      className={className}
      to={completeTrackingURL}
      data-testid="layout-me"
    >
      <StyledInnerProfile
        $collapsed={collapsed}
        $hasProfileProgressIndicator={hasProfileProgressIndicator}
        $isOnMeMenu={isOnMeMenu}
      >
        <StyledProfileImage
          $collapsed={collapsed}
          size={collapsed ? 'small' : 'medium'}
          profileName={displayName}
          src={image}
          type={genderMap[gender]}
          progressBadge={
            collapsed && showProfileFillingIndicator ? (
              <ProgressBadge
                progress={Math.round(score * 100)}
                data-testid="me-profile-progress-badge-collapsed"
              />
            ) : undefined
          }
        />

        <StyledFigcaption
          $collapsed={collapsed}
          $hasProfileProgressIndicator={hasProfileProgressIndicator}
        >
          {displayFlag && (
            <UserFlag
              data-qa="me-badge"
              displayFlag={displayFlag}
              showBasicFlag
              /* Uer Flag size */
              size={isOnMeMenu ? 'small' : 'medium'}
            />
          )}
          {isOnMeMenu ? (
            <StyledMeMenuUserName $displayName={displayName} size="xxlarge">
              {displayName}
            </StyledMeMenuUserName>
          ) : (
            <StyledUserName $collapsed={collapsed} $displayName={displayName}>
              {displayName}
            </StyledUserName>
          )}

          {!collapsed && showProfileFillingIndicator ? (
            <StyledProgressBadge
              progress={Math.round(score * 100)}
              data-testid="me-profile-progress-badge"
            />
          ) : null}
        </StyledFigcaption>
      </StyledInnerProfile>
    </StyledLink>
  );
};
