import styled, { css } from 'styled-components';

import {
  cornerRadiusXL,
  scale005,
  scale060,
  scale070,
  scale100,
  spaceS,
  spaceXXS,
  xdlColorBackgroundDark,
  xdlColorBackgroundLight,
  xdlColorBackgroundTertiary,
  xdlColorMarkerHighlight,
  xdlColorMarkerHighlightText,
  xdlColorMarkerMatch,
  xdlColorMarkerMatchText,
  xdlColorMarkerStrong,
  xdlColorText,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlPaletteOrange06,
  xdlPalettePurpleRain05,
  xdlPalettePurpleRain07,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import type { MarkerVariant } from '.';

type MarkerTProps = {
  $ellipsis?: boolean;
  $variant?: MarkerVariant;
};

type IconContainerTProps = {
  $iconAfter?: boolean;
  $variant?: MarkerVariant;
};

type TextTProps = {
  $ellipsis?: boolean;
  $iconAfter?: boolean;
  $maxCharacters: string;
  $variant?: MarkerVariant;
};

// *** Variants ***
export const variants = {
  default: {
    marker: css`
      background: ${xdlColorBackgroundTertiary};
    `,
    text: css`
      color: ${xdlColorText};
    `,
  },
  match: {
    marker: css`
      background: ${xdlColorMarkerMatch};
    `,
    text: css`
      color: ${xdlColorMarkerMatchText};
    `,
  },
  highlight: {
    marker: css`
      background: ${xdlColorMarkerHighlight};
    `,
    text: css`
      color: ${xdlColorMarkerHighlightText};
    `,
  },
  strong: {
    marker: css`
      background: ${xdlColorMarkerStrong};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  onColor: {
    marker: css`
      background: ${xdlColorBackgroundLight};
    `,
    text: css`
      color: ${xdlColorTextOnLight};
    `,
  },
  onColorStrong: {
    marker: css`
      background: ${xdlColorBackgroundDark};
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
  ai: {
    marker: css`
      background: radial-gradient(
          74.24% 367.5% at 8.59% 87.5%,
          ${xdlPaletteOrange06} 0%,
          rgba(138, 44, 232, 0) 100%
        ),
        radial-gradient(
          41.7% 190% at 93.43% 10%,
          ${xdlPalettePurpleRain05} 0%,
          ${xdlPalettePurpleRain07} 100%
        );
    `,
    text: css`
      color: ${xdlColorTextOnDark};
    `,
  },
};

// *** Components ***
export const Marker = styled.span<MarkerTProps>`
  align-items: center;
  border-radius: ${cornerRadiusXL};
  display: flex;
  height: ${scale100};
  line-height: 1.25;
  padding-block-end: ${scale005};
  padding-block-start: ${spaceXXS};
  padding-inline-end: ${spaceS};
  padding-inline-start: ${spaceS};

  ${(props) => css`
    ${props.$variant && variants[props.$variant].marker};
    ${props.$ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
    `};
  `};
`;

export const IconContainer = styled.span<IconContainerTProps>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: ${scale070};

  ${(props) => css`
    ${props.$variant && variants[props.$variant].text};
    ${props.$iconAfter
      ? css`
          margin-inline-start: ${spaceXXS};
          margin-inline-end: -${spaceXXS};
          order: 1;
        `
      : css`
          margin-inline-end: ${spaceXXS};
          margin-inline-start: -${spaceXXS};
        `}
  `};
`;

export const Text = styled(BodyCopy)<TextTProps>`
  ${(props) => css`
    ${props.$ellipsis &&
    props.$maxCharacters &&
    css`
      display: block;
      max-width: ${props.$maxCharacters}ch;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

    ${props.$variant && variants[props.$variant].text};

    ${props.$variant &&
    (props.$variant === 'strong' ||
      props.$variant === 'onColorStrong' ||
      props.$variant === 'ai') &&
    css`
      font-size: ${scale060};
      font-weight: bold;
    `}
  `};
`;
